import ScTable from './sc-table.vue'
import ScAdd from './sc-add.vue'
import ScEdit from './sc-edit.vue'
import ScView from './sc-view.vue'

export default {
    install(app) {
        app.component('sc-table', ScTable)
        app.component('sc-add', ScAdd)
        app.component('sc-edit', ScEdit)
        app.component('sc-view', ScView)
    }
} 